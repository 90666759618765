<!-- <template>
  <div>
    <SeoKit />
    <TheTopNav />
    <main class="w-full min-h-screen primary-content flex flex-col relative overflow-hidden">
      <div class="p-3 pb-20 w-full m-auto" style="min-height:70vh;z-index: 1;">
        <NuxtPage :transition="false" />
      </div>
      <div class="gradient-bg" style="z-index:0;width:120%;left:-10%;top:0"></div>
      <TheFooter class="mt-auto" />
    </main>

  </div>
</template> -->

<template>

  <div class="drawer">
    <SeoKit />
    <input
      id="my-side-drawer"
      ref="drawerInput"
      type="checkbox"
      class="drawer-toggle"
      v-model="drawerOpen"
    />
    <div class="drawer-content flex flex-col">
      <!-- Navbar -->
      <div class="w-full navbar max-w-screen-2xl m-auto">
        <div class="flex-none lg:hidden">
          <label
            for="my-side-drawer"
            aria-label="open sidebar"
            class="btn btn-square btn-ghost"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="inline-block w-6 h-6 stroke-current"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </label>
        </div>
        <div class="flex-1">
          <div class="">
            <NuxtLink
              to="/"
              class="logo-dark hidden"
            >
              <img
                src="@/assets/img/logo-wordmark-dark.svg"
                alt="Logo"
                width="150px"
                height="41px"
              >
            </NuxtLink>
            <NuxtLink
              to="/"
              class="logo-light hidden"
            >
              <img
                src="@/assets/img/logo-wordmark-light.svg"
                alt="Logo"
                width="150px"
                height="41px"
              >
            </NuxtLink>
          </div>
        </div>
        <div class="flex-none hidden lg:block">
          <TheTopNav />
        </div>
        <!-- <div>
          <nuxt-link
            to="/studio"
            class="btn btn-primary btn-sm lg:hidden"
          >
            Live Studio
          </nuxt-link>
        </div> -->
      </div>
      <!-- Page content here -->
      <NuxtPage
        :transition="false"
        class="pageContent"
      />
      <TheFooter class="mt-auto" />
    </div>
    <div class="drawer-side z-30">
      <label
        ref="drawerLabel"
        for="my-side-drawer"
        aria-label="close sidebar"
        class="drawer-overlay"
      ></label>
      <div class="menu p-4 w-80 min-h-full bg-base-200 text-base-content z-50">
        <TheTopNav
          :mode="'sidebar'"
          @closeDrawer="closeDrawer"
        />
      </div>

    </div>
  </div>

</template>

<style>
  
.pageContent {
  min-height: 70vh;
}

</style>

<script setup>

import { useRoute } from 'vue-router'

const drawerLabel = ref(null)
const route = useRoute()
const drawerInput = ref(null)
const drawerOpen = ref(false)

watch(() => route.path, (newPath, oldPath) => {
  // console.log('route changed:', newPath)
  if (drawerLabel.value) {
    drawerOpen.value = false
  }
})

function closeDrawer() {
  drawerOpen.value = false
}

useSchemaOrg([
  // @todo Select Identity: https://unhead-schema-org.harlanzw.com//guide/guides/identity
  defineOrganization({
    name: 'AIhairstyles.com',
    logo: 'https://ik.imagekit.io/aicosmetic/Image%20Logo%201.svg?updatedAt=1691105907977',
  }),
  defineWebSite({
    name: 'AI hairstyles',
  }),
  defineWebPage(),
])

</script>